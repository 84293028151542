// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Import the dev tools and initialize them
// import { TempoDevtools } from "tempo-devtools"
// TempoDevtools.init();

import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

// Before you render your app
// ReactDOM.render(<App />, document.getElementById("root"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
